/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useState, useEffect } from 'react'
import {useParams, useSearchParams, useLocation, useNavigate, Navigate} from "react-router-dom";
import { Popover, Transition } from '@headlessui/react'
import {
  CashIcon,
  CubeIcon,
  UserGroupIcon,
  GlobeIcon,
  MailIcon,
  MenuIcon,
  PlayIcon,
  XIcon,
} from '@heroicons/react/outline'
import { ChevronDownIcon } from '@heroicons/react/solid'
import Logo from '../assets/Logo.png';
import { HashLink } from 'react-router-hash-link';

const solutions = [
  {
    name: 'Why GiveMasjid?',
    href: '/#issue',
    icon: CashIcon,
  },
  {
    name: 'How it Works',
    href: '/#platform',
    icon: CubeIcon,
  },
  { 
    name: 'Testimonials', 
    href: '/#testimonials', 
    icon: UserGroupIcon 
  },
  { 
    name: 'Video', 
    href: '/#video', 
    icon: PlayIcon 
  },
  {
    name: 'Mission',
    href: '/#mission',
    icon: GlobeIcon,
  },
  { 
    name: 'Contact', 
    href: '/#reach-us', icon: MailIcon 
  },

]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function Example() {
  
 return (
    <Popover className="relative bg-white">
        {({ open }) => (
          <>
            <div className="absolute inset-0 z-30 pointer-events-none" aria-hidden="true" />
            <div className="relative z-20 ">
              <div className="max-w-md sm:max-w-3xl lg:max-w-screen-2xl mx-auto flex justify-between items-center px-2 py-5 sm:px-6 sm:py-4 md:justify-start md:space-x-2 lg:space-x-10">
      {/*          <div>
                  <a href="#" className="flex">
                    <span className="sr-only">GiveMasjid</span>
                    <img
                      className=" w-48"
                      src={Logo}
                      alt=""
                    />
                  </a>
                </div>*/}
                <div className="-mr-2 -my-2 md:hidden">
                  <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                    <span className="sr-only">Open menu</span>
                    <MenuIcon className="h-6 w-6" aria-hidden="true" />
                  </Popover.Button>
                </div>
                <div className="hidden md:flex-1 md:flex md:items-center md:justify-between">
                  <Popover.Group as="nav" className="md:hidden lg:flex lg:space-x-10">
                  </Popover.Group>
                  <div className="flex items-center md:space-x-8">
                    <HashLink smooth to="/#issue" className="lg:text-base sm:text-sm font-medium text-gray-600 hover:text-indigo-900 font-medium hover:font-bold transition-all">
                      Why GiveMasjid?
                    </HashLink>
                    <HashLink smooth to="/#platform" className="lg:text-base sm:text-sm font-medium text-gray-600 hover:text-indigo-900 font-medium hover:font-bold transition-all">
                      How it Works
                    </HashLink>
                    <HashLink smooth to="/#testimonials" className="lg:text-base sm:text-sm font-medium text-gray-600 hover:text-indigo-900 font-medium hover:font-bold transition-all">
                      Testimonials
                    </HashLink>
                    <HashLink smooth to="/#video" className="lg:text-base sm:text-sm font-medium text-gray-600 hover:text-indigo-900 font-medium hover:font-bold transition-all">
                      Video
                    </HashLink>
                    <HashLink smooth to="/#mission" className="lg:text-base sm:text-sm font-medium text-gray-600 hover:text-indigo-900 font-medium hover:font-bold transition-all">
                      Mission
                    </HashLink>
                    {/*  <a href="https://articles.givemasjid.com" className="lg:text-base font-medium text-gray-600 hover:text-indigo-900 font-medium hover:font-bold transition-all">
                        Articles
                      </a> */}
                    <HashLink smooth to="#reach-us" className="lg:text-base sm:text-sm  font-medium text-gray-600 hover:text-indigo-900 font-medium hover:font-bold transition-all">
                      Contact
                    </HashLink>
                    <a
                      href="/request-trial"
                      className="ml-8 inline-flex items-center sm:text-sm  justify-center px-4 py-2 border border-slate-300 rounded-md shadow-sm lg:text-base font-medium font-medium text-black hover:bg-indigo-600 hover:text-white transition-all hover:font-bold transition-all"
                    >
                      Try GiveMasjid
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <Transition
              as={Fragment}
              enter="duration-200 ease-out"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="duration-100 ease-in"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Popover.Panel
                focus
                className="absolute z-30 top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden"
              >
                  <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y-2 divide-gray-50">
                    <div className="pt-5 pb-6 px-5 sm:pb-8">
                      <div className="flex items-center justify-between">
                        <div>
                          <img
                            className="h-8 w-auto"
                            src={Logo}
                            alt="GiveMasjid"
                          />
                        </div>
                        <div className="-mr-2">
                          <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                            <span className="sr-only">Close menu</span>
                            <XIcon className="h-6 w-6" aria-hidden="true" />
                          </Popover.Button>
                        </div>
                      </div>
                      <div className="mt-6 sm:mt-8">
                          <div className="grid gap-7 sm:grid-cols-2 sm:gap-y-8 sm:gap-x-4">
                            {solutions.map((item) => (
                                <HashLink smooth to={item.href}
                                  key={item.name}
                                  className="-m-3 flex items-center p-3 rounded-lg hover:bg-gray-50"
                                >
                                  <div className="flex-shrink-0 flex items-center justify-center h-10 w-10 rounded-md bg-indigo-500 text-white sm:h-12 sm:w-12">
                                    <item.icon className="h-6 w-6" aria-hidden="true" />
                                  </div>
                                  <div className="ml-4 text-base font-medium text-gray-900">{item.name}</div>
                                </HashLink>
                            ))}
                          </div>
                      </div>
                    </div>
                    <div className="pb-6 px-5">
                      <div className="mt-3">
                        <a
                          href="/request-trial"
                          className="w-full flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-indigo-600 hover:bg-indigo-700"
                        >
                          Request a Trial
                        </a>

                      </div>
                    </div>
                  </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
    </Popover>
  )
}
