// React Router
import {
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom";


// Components
import Home from "./pages/home.jsx"
import Trial from "./pages/trial.jsx"
import Registration from "./pages/registration.jsx"
import Karima from "./pages/karima.jsx"
import Barakah from "./pages/barakah.jsx"
import Redirect from "./pages/redirect.jsx"

function App() {
  return (
    <div className="App h-screen">
      <BrowserRouter>

        <Routes> 
          <Route path="/" element={<Home />} />
          <Route path="/info" element={<Home />} />
          <Route path="/karima" element={<Karima />} />
          <Route path="/barakah" element={<Barakah />} />
          <Route path="/request-trial" element={<Trial />} />
          <Route path="/register" element={<Registration />} />

          {/*Redirects for app urls*/}
          <Route path='/grassroots' element={<Redirect destination="grassroots"/>}/>
          <Route path='/isma' element={<Redirect destination="isma"/>}/>
          <Route path='/albayan' element={<Redirect destination="albayan"/>}/>
          <Route path='/albayan/terminal1' element={<Redirect destination="albayan?terminal=true&reader=tmr_ExCVtAFRRR3Crz&live=true"/>}/>
          <Route path='/albayan/terminal2' element={<Redirect destination="albayan?terminal=true&reader=tmr_Exb5JwoSEVezX9&live=true"/>}/>
          <Route path='/scranton' element={<Redirect destination="scranton"/>}/>
          <Route path='/maskarima' element={<Redirect destination="maskarima"/>}/>
          <Route path='/malv' element={<Redirect destination="malv"/>}/>
          <Route path='/ISRI' element={<Redirect destination="ISRI"/>}/>


          {/*Wildcard*/}
          <Route path="/*" element={<Home />} />

        </Routes>
      </BrowserRouter>


    </div>
  );
}

export default App;
