/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useState, useEffect } from 'react'
import {useParams, useSearchParams, useLocation, useNavigate, Navigate} from "react-router-dom";

// Components
import Banner from "../components/banner.jsx"
import Nav from "../components/Nav.jsx"
import Hero from "../components/Hero.jsx"
import Headline from "../components/Headline.jsx"
import Platform from "../components/Platform.jsx"
import Platform2 from "../components/Platform2.jsx"

import Stats from "../components/Stats.jsx"
import Mission from "../components/Mission.jsx"
import Testimonials from "../components/Testimonials.jsx"
import Video from "../components/Video.jsx"
import TestimonialHighlight from "../components/TestimonialHighlight.jsx"
import MasjidTestimonials from "../components/MasjidTestimonials.jsx"
import CTA from "../components/CTA.jsx"
import Footer from "../components/Footer.jsx"

function Home() {

 // Capture search parameter for the purpose of 
  let location = useLocation();

  useEffect(() => {

    var hash = location.hash.substring(1); 
    console.log(hash);

    if(hash== ""){
      window.scrollTo(0, 0);
    }

    if(document.getElementById(hash) != null) {
      setTimeout(() => {
        document.getElementById(hash).scrollIntoView();
      }, 250)      
    }

  }, [location]);

  return (
    <div>
      <Banner />
      <Nav />
      <Hero />
      <Headline />
      <Platform />
      <Stats />
      {/* <TestimonialHighlight /> */}
      <Platform2 />
      <MasjidTestimonials />
      <Testimonials />
      {/* <MasjidTestimonials /> */}
      <Video />
      <Mission />
      <CTA />
      <Footer />
    </div>
  );
}

export default Home;
