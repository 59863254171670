/* This example requires Tailwind CSS v2.0+ */
import { ExternalLinkIcon } from '@heroicons/react/solid'

export default function Example() {
  return (
    <div className="relative mx-auto bg-gray-800 " id="reach-us">
      <div className="h-56 mx-auto bg-indigo-600 sm:h-72 md:absolute md:left-0 md:h-full md:w-1/2">
        <img
          className="w-full h-full object-cover"
          src="https://images.unsplash.com/photo-1573939705721-9fa2cdcda901?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80&blend=6366F1&sat=-100&blend-mode=multiply"
          alt=""
        />
      </div>
      <div className="relative max-w-7xl mx-auto px-4 py-12 sm:px-6 lg:px-8 lg:py-16">
        <div className="md:ml-auto md:w-1/2 md:pl-10">
          <h2 className="text-base font-semibold uppercase tracking-wider text-gray-300">Reach Out to Us</h2>
          <p className="mt-2 text-white text-3xl font-extrabold tracking-tight sm:text-4xl">We’re Here to <span className="text-indigo-400">Help</span></p>
          <p className="mt-3 text-base sm:text-lg text-gray-300">
            We believe that masjids play an important role for the communities they serve. If your masjid is looking to achieve a greater level of impact, drop us a line to learn how GiveMasjid can help.
          </p>
          <div className="mt-8">
              <div className="mt-10">
                <div className="mt-3">
                    <a
                      href="/request-trial"
                      className="flex  px-8 py-3 border bg-indigo-900 border-slate-300 text-md rounded-md text-white bg-transparent border-indigo-100 hover:bg-indigo-600 hover:text-white transition-all "
                    >
                      > Request a Trial
                    </a>
                  </div>
{/*                <div className="mt-3">
                    <a
                      href="/FAQ" target="_blank"
                      className="flex  px-8 py-3 border border-slate-300 text-sm rounded-md text-white bg-transparent border-indigo-100 hover:bg-indigo-700  transition-all"
                    >
                      > Check Out Our FAQ
                    </a>
                  </div>*/}
                <div className="mt-3">
                    <a
                      className="flex  px-8 py-3 border border-slate-300 text-md rounded-md text-white bg-transparent border-indigo-100 hover:bg-indigo-200 hover:text-black transition-all"
                    >
                      > Email Us: salam@givemasjid.com
                    </a>
                  </div>
                <div className="mt-3">
                    <a
                      className="flex  px-8 py-3 border border-slate-300 text-md rounded-md text-white bg-transparent border-indigo-100 hover:bg-indigo-200 hover:text-black  transition-all"
                    >
                      > Call or Text Us: (415) 909-4596
                    </a>
                  </div>


              </div>
          </div>
        </div>
      </div>
    </div>
  )
}
