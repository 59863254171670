import { HashLink } from 'react-router-hash-link';

const navigation = {
  main: [
    { name: 'Home', href: '/#' },
    { name: 'Why GiveMasjid?', href: '/#issue' },
    { name: 'How It Works', href: '/#platform' },
    { name: 'Testimonials', href: '/#testimonials' },
    { name: 'Mission', href: '/#mission' },
    // { name: 'Features', href: '/features' },
    // { name: 'Articles', href: 'https://articles.givemasjid.com' },
    // { name: 'FAQ', href: '/faq' },
    // { name: 'Reach Us', href: '/#reach-us' },
    { name: 'Video', href: '/#video' },
    { name: 'Request Trial', href: '/request-trial' },
    { name: 'Register', href: '/register/#' },
  ]
}
export default function Example() {
  return (
    <footer className="bg-white">
      <div className="max-w-7xl mx-auto py-6 sm:py-12 px-4 overflow-hidden sm:px-6 lg:px-8">
        <nav className="-mx-5 -my-2 flex flex-wrap justify-center" aria-label="Footer">
          {navigation.main.map((item) => (
            <div key={item.name} className="px-2 sm:px-5 sm:py-2">
              <HashLink smooth to={item.href} className="text-xs sm:text-base text-gray-500 hover:text-gray-900">
                {item.name}
              </HashLink>
            </div>
          ))}
            <div className="px-2 sm:px-5 sm:py-2"> 
              <a href="https://status.givemasjid.com/" className="text-xs sm:text-base text-gray-500 hover:text-gray-900">Platform Status</a>
            </div>
        </nav>
        <p className="text-xs mt-3 text-center sm:text-base text-gray-400">&copy; 2024 GiveMasjid - <a href="https://app.termly.io/document/terms-of-use-for-website/fa7aba0d-934e-48b1-874f-ce4d3865b5ab"> Terms and Conditions</a> - <a href="https://app.termly.io/document/privacy-policy/ce85fd8e-dadb-4ea5-9bea-e7d16dc57ab1"> Privacy Policy </a></p>
      </div>
    </footer>
  )
}
