import React, { useState } from 'react';
import { TrendingUpIcon } from '@heroicons/react/outline';

export default function Example() {
  const [showBanner, setShowBanner] = useState(true);

  const dismissBanner = () => {
    setShowBanner(false);
  };

  return (
    <>
      {showBanner && (
        <div className="relative bg-cyan-900">
          <div className="max-w-7xl mx-auto py-3 px-3 sm:px-6 lg:px-8">
            <div className="text-center sm:px-16">
              <p className="font-medium text-white">
                <span className="hidden lg:inline">
                  Alhamdulillah, to date, we've helped masjids collect nearly <b>$500k</b> through close to <b>4,000</b> charitable donations... and <i>counting!</i>
                </span>
                <span className="inline sm:ml-2 sm:inline-block lg:hidden text-sm">
                  Over <b>$275k</b> in donations raised... and counting!

                </span>
              </p>
            </div>
            <div className="absolute inset-y-0 right-0 pt-1 pr-1 flex items-start sm:pt-1 sm:pr-2 sm:items-start">
              <button
                type="button"
                className="flex p-2 rounded-md hover:bg-cyan-800 focus:outline-none focus:ring-2 focus:ring-white"
                onClick={dismissBanner}
              >
                <span className="sr-only">Dismiss</span>
                <TrendingUpIcon className="h-6 w-6 text-white text-sm" aria-hidden="true" />
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
