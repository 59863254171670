import Logo from '../assets/Logo.png';
import Nav from "./Nav.jsx"
import { HashLink } from 'react-router-hash-link';
import Masjid3 from '../assets/Wallpaper_H5.jpg'; 
import Masjid from '../assets/Wallpaper_H5.png'; 


import { ChevronRightIcon, StarIcon } from '@heroicons/react/solid'

export default function Example() {
  return (
    <div className="mx-auto bg-white sm:pb-12 lg:pb-12 max-w-screen-2xl">
      <div className="overflow-hidden sm:pt-12 md:pt-2 lg:relative lg:py-36">

        <div className="mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl lg:grid lg:grid-cols-2 lg:gap-24">
          <div>

          <div>
            <a href="#" className="flex">
              <span className="sr-only">GiveMasjid</span>
              <img
                className="w-40 pt-2 sm:w-48"
                src={Logo}
                alt=""
              />
            </a>
          </div>
            <div className="mt-10">
              <div className="mt-6 sm:max-w-xl">
                <h1 className="text-3xl font-extrabold text-gray-900 tracking-tight sm:text-5xl">
                  <span className="block inline">Grow Your</span>{' '}
                  <span className="block inline text-indigo-600">Masjid</span>.
                </h1>
                <p className="mt-6 sm:text-xl text-gray-500">
                  With <b>GiveMasjid</b> you can modernize your donation system, increase giving, and exceed your fundraising goals.
                </p>
                <div className="mt-5 sm:mt-8 sm:flex sm:justify-start md:justify-start">
                  <div className="rounded-md shadow">
                    <a
                      href="/request-trial"
                      className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 md:py-4 md:text-lg md:px-10 hover:font-bold hover:md:text-xl transition-all"
                    >
                      > Request a Trial
                    </a>
                  </div>
                  <div className="mt-3 sm:mt-0 sm:ml-3">
                    <HashLink smooth to="/#issue"
                      className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-indigo-500 bg-indigo-100 hover:bg-indigo-200 md:py-4 md:text-lg md:px-10 hover:font-bold hover:md:text-xl transition-all"
                    >
                      > Learn More
                    </HashLink>
                  </div>
                </div>
              </div>

              <div className="mt-6">
                <div className="inline-flex items-center divide-x divide-gray-300">
                  <div className="flex-shrink-0 flex pr-5">
                    <StarIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" />
                    <StarIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" />
                    <StarIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" />
                    <StarIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" />
                    <StarIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" />
                  </div>
                  <div className="min-w-0 flex-1 pl-5 py-1 text-sm text-gray-900 sm:py-3">
                    <span className="text-indigo-600 font-bold">No Upfront </span> or <span className="text-indigo-600 font-bold"> Annual Fees. </span> Try Risk-Free.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="sm:mx-auto sm:max-w-3xl sm:px-6">
          <div className="py-6 sm:relative sm:mt-12 sm:py-16 md:py-2 lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
            <div className="hidden sm:block">
              <div className="absolute inset-y-0 left-1/2 w-screen bg-gray-50 rounded-l-3xl lg:left-80 lg:right-0 lg:w-full" />
              <svg
                className="absolute top-8 right-1/2 -mr-3 lg:m-0 lg:left-0"
                width={404}
                height={392}
                fill="none"
                viewBox="0 0 404 392"
              >
                <defs>
                  <pattern
                    id="837c3e70-6c3a-44e6-8854-cc48c737b659"
                    x={0}
                    y={0}
                    width={20}
                    height={20}
                    patternUnits="userSpaceOnUse"
                  >
                    <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
                  </pattern>
                </defs>
                <rect width={404} height={392} fill="url(#837c3e70-6c3a-44e6-8854-cc48c737b659)" />
              </svg>
            </div>
            <div className="sm:pt-10 relative -mr-40 sm:mx-auto sm:max-w-3xl sm:px-0 lg:max-w-none lg:h-full lg:pl-12">

              <img
                className="w-3/4 sm:w-full sm:rounded-md shadow-xl ring-1 ring-black ring-opacity-5"
                src={Masjid}
                // src="https://images.unsplash.com/photo-1575443579199-cb1b77b2932e?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1548&q=80"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
