/* This example requires Tailwind CSS v2.0+ */
import { AnnotationIcon, GlobeAltIcon, MailIcon, ScaleIcon, CashIcon, TrendingDownIcon, UserGroupIcon, CreditCardIcon, TrendingUpIcon, PresentationChartBarIcon, KeyIcon, CurrencyDollarIcon, HeartIcon, GiftIcon, MoonIcon, PresentationChartLineIcon, QuestionMarkCircleIcon, ClockIcon, UsersIcon} from '@heroicons/react/outline'
import box2 from '../assets/Box1.png'; 
import box1 from '../assets/Box2.png';
import box3 from '../assets/Box3.jpg';
import boxes from '../assets/Boxes.gif';
import app from '../assets/App1.png'; 
import app2 from '../assets/App2.png'; 
import jummah from '../assets/jummah.png'; 
import appvid from '../assets/AppVid.gif'; 
import flyer from '../assets/flyer_shot.png'; 
import flyer_app2 from '../assets/Flyer_App_Combo_2.png'; 
import flyer_app from '../assets/Flyer_App_Combo.png'; 
import { HashLink } from 'react-router-hash-link';


const transferFeatures = [
  {
    id: 1,
    name: "Most Donors Don't Carry Cash",
    description:
      "In the real-world, your masjid's attendees make ~90% of their transactions through credit cards and mobile payments. If your masjid still receives most donations in cash — you are missing out on a major opportunity.",
    icon: CashIcon,
  },
  {
    id: 2,
    name: "Cash Donors Can't Be Identified",
    description:
      'When donors contribute through cash, your masjid has no way of identifying its biggest donors. Your masjid loses access to the hidden champions in your community, who are often willing to offer more if directly asked.',
    icon: UserGroupIcon,
  },
  {
    id: 3,
    name: "Cash Boxes Cloud Transparency",
    description:
      "When donors see donation boxes passed around at masjids, they lose trust in the masjid's use of funding. Since donors can't be clear on the masjid's financial goals and how much has already been raised, they assume the masjid has already collected enough and hold back on large-sum donations ",
    icon: QuestionMarkCircleIcon,
  },
]
const communicationFeatures = [
  {
    id: 4,
    name: 'Mobile Donations Encouraged',
    description:
      'Through GiveMasjid, your masjid will have its very own mobile-optimized donation system. Flyers powered by QR codes will direct your donors to simple pages where they can make a credit card or mobile donations in seconds. No app download required.',
    icon: CreditCardIcon,
  },
  {
    id: 10,
    name: 'Automated Jummah Collection',
    description:
      "Through GiveMasjid, your donors can set up an automated donations to your masjid on a daily, weekly, monthly, or yearly basis. Each Friday donors can especially be nudged to sign up to offer a recurring Jummah donation. No more forgotten donations!",
    icon: ClockIcon,
  }
]

const features3 = [
  {
    id: 9,
    name: 'No Upfront or Annual Fee',
    description:
      "We want you to try GiveMasjid risk-free. We'll cover all costs to set the platform up for your masjid at no cost and we'll offer you dedicated support to ensure the implementation is successful. If ultimately GiveMasjid is not for you, we'll help you find a different solution.",
    icon: HeartIcon,
  },
  {
    id: 9,
    name: 'Low to No-Cost Transaction Costs',
    description:
      "As a Muslim-run organization, we're looking to help masjids keep what they raise. Our fees are among the lowest of any donation platform out there. We charge only a 0.80% platform fee to cover our expenses. And because of how GiveMasjid is setup, the vast majority of these fees are covered by donors themselves.",
    icon: ScaleIcon,
  },
  {
    id: 7,
    name: 'Simple Financial Reporting',
    description:
      'GiveMasjid helps you to manage incoming donations simply. Your masjid will have acess to intuitive reports — complete with donation history, analytics, and customizable settings. Your donors will receive an instant email with their tax deduction receipts.',
    icon: PresentationChartBarIcon,
  },
  {
    id: 6,
    name: 'Secured Transactions ',
    description:
      'GiveMasjid is built on top of the  Stripe API, the same payment processor used by Amazon, Salesforce, and Lyft. Your donors can trust that their data is safe and securely handled through a PCI-compliant system that will feel familiar to them.',
    icon: KeyIcon,
    },
    {
      id: 8,
      name: 'Next Day Deposits',
      description:
        "All donations deposit directly into your masjid's bank account. Funds can be withdrawn within 1-2 days of receipt along with weekly reports on donations received.",
      icon: CurrencyDollarIcon,
    },
]

const features4 = [
  {
    id: 5,
    name: 'Goal Tracking Displays',
    description:
      "Through GiveMasjid, your masjid can set and communicate its weekly, monthly, or annual fundraising goals to donors. Transparency encourages more donations week over week and month over month.",
    icon: TrendingUpIcon,
  },
  {
    id: 9,
    name: 'Masjid Specfic Causes',
    description:
      "As your masjid runs specific initiatives, your donors can choose to donate to the causes that resonate with them the most. You can use this to collect one-time or subscription based membership dues, Islamic school fees, Sadaqah donations, maintenance pledges, or add the causes of your choosing.",
    icon: GiftIcon,
  },
  {
    id: 8,
    name: 'Islamic Calendar Reminders',
    description:
      "Whether it's Ramadan, Eid-Al-Fitr, Laylat Al-Qadr, or the first 10 Days of Dhul Hijjah - donors will be encouraged to especially give during the most blessed times of the year.",
    icon: MoonIcon,
  },
  {
    id: 6,
    name: 'Donor Matching Incentives',
    description:
      "As your masjid receives donations, donors will see anonymized prompts showcasing your masjid's largest donations. Subtle nudges encourage donors to offer higher sums than they might otherwise.",
    icon: UsersIcon,
    }
]

export default function TailwindFeature1() {
  return (
    <div className="bg-white overflow-hidden sm:py-24 md:py-6">
      <div className="relative max-w-xl mx-auto px-4 sm:px-6 lg:px-8 lg:max-w-7xl" id="issue">

        {/* Feature 2 */}
        <div className="relative mt-8 lg:mt-10 lg:grid lg:grid-cols-2 lg:gap-8 lg:items-center pt-6">
          <div className="relative">
            <h3 className="text-2xl font-extrabold text-gray-900 tracking-tight sm:text-3xl">
              Built For Muslims By <span className="text-indigo-600">Muslims</span>.
            </h3>
            <p className="mt-3 sm:text-lg text-gray-500">
              As a Muslim run organization, we understand the Muslim community. We've created a whole host of features specifically aligned to encourage Islamic giving.
            </p>

            <dl className="mt-10 space-y-10">
              {features4.map((item) => (
                <div key={item.id} className="relative">
                  <dt>
                    <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-indigo-500 text-white">
                      <item.icon className="h-6 w-6" aria-hidden="true" />
                    </div>
                    <p className="ml-16 text-lg leading-6 font-medium text-gray-900">{item.name}</p>
                  </dt>
                  <dd className="mt-2 ml-16 text-base text-gray-500">{item.description}</dd>
                </div>
              ))}
            </dl>
              {/* <div className="text-center mt-10">
                <div className="mt-3 sm:mt-0">
                    <a
                      href="/request-trial"
                      className="flex items-center justify-center px-8 py-3 border shadow-md text-sm font-medium rounded-md text-indigo-700 bg-indigo-100 border-slate-300 border-3 hover:bg-indigo-600 hover:text-white hover:font-bold"
                    >
                      > Request a Trial
                    </a>
                </div>
              </div> */}
          </div>


          <div className="mt-10 -mx-4 relative lg:mt-0" aria-hidden="true">
            <svg
              className="absolute left-1/2 transform -translate-x-1/2 translate-y-16 lg:hidden"
              width={784}
              height={404}
              fill="none"
              viewBox="0 0 784 404"
            >
              <defs>
                <pattern
                  id="ca9667ae-9f92-4be7-abcb-9e3d727f2941"
                  x={0}
                  y={0}
                  width={20}
                  height={20}
                  patternUnits="userSpaceOnUse"
                >
                  <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
                </pattern>
              </defs>
              <rect width={784} height={404} fill="url(#ca9667ae-9f92-4be7-abcb-9e3d727f2941)" />
            </svg>
            <img
              className="relative mx-auto sm:rounded-3xl"
              width={800}
              src={jummah}
              alt=""
            />
          </div>
        </div>

        {/* Graphic */}
        <svg
          className="hidden lg:block absolute right-full transform translate-x-1/2 translate-y-12"
          width={404}
          height={784}
          fill="none"
          viewBox="0 0 404 784"
          aria-hidden="true"
        >
          <defs>
            <pattern
              id="64e643ad-2176-4f86-b3d7-f2c5da3b6a6d"
              x={0}
              y={0}
              width={20}
              height={20}
              patternUnits="userSpaceOnUse"
            >
              <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
            </pattern>
          </defs>
          <rect width={404} height={784} fill="url(#64e643ad-2176-4f86-b3d7-f2c5da3b6a6d)" />
        </svg>

        {/* Feature 3 */}
        <div className="relative mt-12 sm:mt-16 lg:mt-24" id="platform">
          <div className="lg:grid lg:grid-flow-row-dense lg:grid-cols-2 lg:gap-8 lg:items-center">
            <div className="lg:col-start-2">
              <h3 className="text-2xl font-extrabold text-gray-900 tracking-tight sm:text-3xl">Masjid Management Made <span className ="text-indigo-600">Easy.</span></h3>
              <p className="mt-3 text-lg text-gray-500">
                We've built GiveMasjid from the ground-up with masjids in mind. Not only will your donors enjoy the donation experience, but so will your masjid administrators. <b>Why?</b>
              </p>

              <dl className="mt-10 space-y-10">
                {features3.map((item) => (
                  <div key={item.id} className="relative">
                    <dt>
                      <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-indigo-500 text-white">
                        <item.icon className="h-6 w-6" aria-hidden="true" />
                      </div>
                      <p className="ml-16 text-lg leading-6 font-medium text-gray-900">{item.name}</p>
                    </dt>
                    <dd className="mt-2 ml-16 text-base text-gray-500">{item.description}</dd>
                  </div>
                ))}
              </dl>
              <div className="text-center mt-10">
                {/* <div className="mt-3 sm:mt-0">
                    <HashLink
                      smooth to="/#reach-us"
                      className="flex items-center justify-center px-8 py-3 border shadow-md text-sm font-medium rounded-md text-indigo-700 bg-indigo-100 border-slate-300 border-3  hover:bg-indigo-600 hover:text-white hover:font-bold"
                    >
                      > Get In Touch
                    </HashLink>
                </div> */}

              </div>
            </div>

            <div className="mt-10 -mx-4 relative lg:mt-0 lg:col-start-1">
              <svg
                className="absolute left-1/2 transform -translate-x-1/2 translate-y-16 lg:hidden"
                width={784}
                height={404}
                fill="none"
                viewBox="0 0 784 404"
                aria-hidden="true"
              >
                <defs>
                  <pattern
                    id="e80155a9-dfde-425a-b5ea-1f6fadd20131"
                    x={0}
                    y={0}
                    width={20}
                    height={20}
                    patternUnits="userSpaceOnUse"
                  >
                    <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
                  </pattern>
                </defs>
                <rect width={784} height={404} fill="url(#e80155a9-dfde-425a-b5ea-1f6fadd20131)" />
              </svg>
              <img
                className="relative mx-auto rounded-3xl"
                width={500}
                src={app}
                alt=""
              />
            </div>
          </div>
        </div>

      </div>
    </div>
  )
}
