/* This example requires Tailwind CSS v2.0+ */
import Mission from '../assets/Mission.jpg';
import Video from "../assets/PrayerVideo.mov"
import { HashLink } from 'react-router-hash-link';


const stats = [
  { label: 'Founded', value: '2021' },
  { label: 'Employees', value: '5' },
  { label: 'Beta Users', value: '521' },
  { label: 'Raised', value: '$25M' },
]

export default function Example() {
  return (
    <div className="relative bg-slate-50 py-16 sm:py-24" id="mission">
      <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:gap-24 lg:items-start">
        <div className="relative sm:py-16 lg:py-0">
          <div aria-hidden="true" className="hidden sm:block lg:absolute lg:inset-y-0 lg:right-0 lg:w-screen">
            <div className="absolute inset-y-0 right-1/2 w-full bg-gray-50 rounded-r-3xl lg:right-72" />
            <svg
              className="absolute top-8 left-1/2 -ml-3 lg:-right-8 lg:left-auto lg:top-12"
              width={404}
              height={392}
              fill="none"
              viewBox="0 0 404 392"
            >
              <defs>
                <pattern
                  id="02f20b47-fd69-4224-a62a-4c9de5c763f7"
                  x={0}
                  y={0}
                  width={20}
                  height={20}
                  patternUnits="userSpaceOnUse"
                >
                  <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
                </pattern>
              </defs>
              <rect width={404} height={392} fill="url(#02f20b47-fd69-4224-a62a-4c9de5c763f7)" />
            </svg>
          </div>
          <div className="relative mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-0 lg:max-w-none lg:py-20">
            <div className="relative pt-64 pb-40 overflow-hidden">
              <img
                className="absolute inset-0 h-full w-full object-cover rounded-xl shadow-xl"
                src="https://images.unsplash.com/photo-1512162056921-1a24bc5dd478?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1740&q=80"
                alt=""
              />

              {/*<div className="absolute inset-0 bg-indigo-500 mix-blend-multiply" />*/}
              {/*<div className="absolute inset-0 bg-gradient-to-t from-indigo-600 via-indigo-600 opacity-90" />*/}
{/*              <div className="relative px-8">
                <div>
                  <img
                    className="h-12"
                    src="https://tailwindui.com/img/logos/workcation.svg?color=white"
                    alt="Workcation"
                  />
                </div>
                <blockquote className="mt-8">
                  <div className="relative text-lg font-medium text-white md:flex-grow">
                    <svg
                      className="absolute top-0 left-0 transform -translate-x-3 -translate-y-2 h-8 w-8 text-indigo-400"
                      fill="currentColor"
                      viewBox="0 0 32 32"
                      aria-hidden="true"
                    >
                      <path d="M9.352 4C4.456 7.456 1 13.12 1 19.36c0 5.088 3.072 8.064 6.624 8.064 3.36 0 5.856-2.688 5.856-5.856 0-3.168-2.208-5.472-5.088-5.472-.576 0-1.344.096-1.536.192.48-3.264 3.552-7.104 6.624-9.024L9.352 4zm16.512 0c-4.8 3.456-8.256 9.12-8.256 15.36 0 5.088 3.072 8.064 6.624 8.064 3.264 0 5.856-2.688 5.856-5.856 0-3.168-2.304-5.472-5.184-5.472-.576 0-1.248.096-1.44.192.48-3.264 3.456-7.104 6.528-9.024L25.864 4z" />
                    </svg>
                    <p className="relative">
                      Tincidunt integer commodo, cursus etiam aliquam neque, et. Consectetur pretium in volutpat, diam.
                      Montes, magna cursus nulla feugiat dignissim id lobortis amet.
                    </p>
                  </div>

                  <footer className="mt-4">
                    <p className="text-base font-semibold text-indigo-200">Sarah Williams, CEO at Workcation</p>
                  </footer>
                </blockquote>
              </div>*/}
            </div>
          </div>
        </div>

        <div className="relative mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-0">
          {/* Content area */}
          <div className="pt-12 sm:pt-16 lg:pt-13">
            <h2 className="text-2xl text-gray-900 font-extrabold tracking-tight sm:text-4xl">
              On A Mission to Support <span className="text-indigo-600"> Masjids</span>.
            </h2>
            <div className="mt-6 text-gray-500 space-y-6">
              <p className="text-base sm:text-lg">
                We started GiveMasjid because we believe that masjids in America are a cornerstone in Muslim communal life.   
              </p>
              <p className="text-base leading-7">
                Yet too often, they hobble along. They struggle to collect sufficient funds to cover their operating expenses — let alone grow and expand. The end result is masjids in the US often look and feel the same way they did when they were first constructed. We believe more can be done to help. 
              </p>
              <p className="text-base leading-7">
                We're applying over a decade of experience as executives, technologists, and entrepreneurs to help invest in our community's institutions. We're focused on helping masjids increase their funding by offering them simple, no-cost tools that improve their system for collecting, managing, and growing donations. And this is just the start.   
              </p>
            </div>
          </div>
          <div className="text-center mt-10">
          <div className="mt-3 sm:mt-0">
              <HashLink smooth to="/request-trial"
                className="flex items-center justify-center px-8 py-3 border shadow-md text-sm font-medium rounded-md text-indigo-700 bg-indigo-100 border-slate-300 border-3 hover:bg-indigo-200 hover:bg-indigo-600 hover:text-white hover:font-bold "
              >
                > Try GiveMasjid Risk-Free
              </HashLink>
          </div>
{/*            <div className="mt-3 sm:ml-3">
              <a
                href="/Articles" target="_blank"
                className="flex items-center justify-center px-8 py-3 border border-slate-300 text-sm font-medium rounded-md text-black bg-transparent border-indigo-100 hover:bg-indigo-200"
              >
                > Read Our Recent Articles
              </a>
            </div>*/}
        </div>

        </div>
      </div>
    </div>
  )
}
