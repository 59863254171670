const stats = [
    { id: 1, name: 'Total Donations Collected', value: '$447,000' },
    { id: 2, name: 'Number of Donations Processed', value: '3,930' },
    { id: 3, name: 'Average Donation Size', value: '$114.87' },
    { id: 4, name: 'Number of Subscription Payments', value: '2,102' },
  ]
  
  export default function Example() {
    return (
      <div className="bg-gray-900 py-24 sm:py-32">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto max-w-2xl lg:max-w-none">
            <div className="text-center">
              {/* <h2 className="text-3xl font-bold tracking-tight text-white sm:text-4xl">Trusted by Masjids and Muslims Nationwide</h2> */}
              <h2 className="text-4xl font-bold tracking-tight text-white sm:text-4xl">Trusted by <span className=" text-emerald-500">Masjids </span>and <span className=" text-emerald-500" >Muslims </span> Nationwide</h2>
              <p className="mt-4 text-lg leading-8 text-gray-300">
                Since we first launched the platform in April 2022, we've tried earnestly to help our masjids in meaningful ways.
              </p>
            </div>
            <dl className="mt-16 grid grid-cols-1 gap-0.5 overflow-hidden rounded-2xl text-center sm:grid-cols-2 lg:grid-cols-4">
              {stats.map((stat) => (
                <div key={stat.id} className="flex flex-col bg-white/5 p-8">
                  <dt className="text-sm font-semibold leading-6 text-gray-300">{stat.name}</dt>
                  <dd className="order-first text-3xl font-semibold tracking-tight text-white">{stat.value}</dd>
                </div>
              ))}
            </dl>
          </div>
        </div>
      </div>
    )
  }
  