import React, {useState, useEffect} from 'react'
import { useForm, ValidationError } from '@formspree/react';
import MyDialog from "../components/successPopup.jsx"
import Logo from '../assets/Logo.png';

export default function Trial() {

  const [state, handleSubmit] = useForm("xvolerwy");
  const [showModal, setShowModal] = useState(false);

  // Check if there's been a change to form state
  useEffect(() => {

    if (state.submitting) {
        setShowModal(true);
    }

  }, [state])

  // Form Dialog
  return (
    <>
      <MyDialog showState = {showModal} setShowState = {setShowModal} />
      <div className="min-h-full flex">
        <div className="flex-1 flex flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
          <div className="mx-auto w-full max-w-sm lg:w-96">
            <div>
            <a href="/">
              <img
                className="h-7 w-auto"
                src={Logo}
                alt="Givemasjid"
              />
            </a>
              <h2 className="mt-6 text-3xl font-extrabold text-gray-900"><span className="text-indigo-600">Barakah</span> Funding Program</h2>
              <p className="mt-2 text-sm text-gray-600">
                The GiveMasjid Barakah Funding Program offers masjids up to $10k in advance cash to cover near-term expenses or projects. Fill out the information below and a member of the GiveMasjid team will be in touch to share more about the program and how it might help you cover your short-term needs. 
              </p>
            </div>

            <div className="mt-8">
              <div>
                <div className="mt-6 relative">
                  <div className="absolute inset-0 flex items-center" aria-hidden="true">
                    <div className="w-full border-t border-gray-300" />
                  </div>
                  <div className="relative flex justify-center text-sm">
                  </div>
                </div>
              </div>

              <div className="mt-6">
                <form onSubmit={handleSubmit} className="space-y-6">
                  <div>
                    <label htmlFor="name" className="block text-sm font-medium text-gray-700">
                      Full Name
                    </label>
                    <div className="mt-1">
                      <input
                        id="name"
                        name="name"
                        type="text"
                        autoComplete="name"
                        required
                        className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      />
                    </div>
                  </div>

                  <div className="space-y-1">
                    <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                      Email Address
                    </label>
                    <div className="mt-1">
                      <input
                        id="email"
                        name="email"
                        type="email"
                        autoComplete="email"
                        required
                        className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      />

                      <ValidationError 
                        prefix="Email" 
                        field="email"
                        errors={state.errors}
                      />

                    </div>
                  </div>

                  <div className="space-y-1">
                    <label htmlFor="phone" className="block text-sm font-medium text-gray-700">
                      Phone Number 
                    </label>
                    <div className="mt-1">
                      <input
                        id="phone"
                        name="phone"
                        type="text"
                        autoComplete="tel"
                        required
                        className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      />
                    </div>
                  </div>

                  <div className="space-y-1">
                    <label htmlFor="masjid" className="block text-sm font-medium text-gray-700">
                      Masjid / Organization Name
                    </label>
                    <div className="mt-1">
                      <input
                        id="masjid"
                        name="masjid"
                        type="text"
                        autoComplete="organization"
                        required
                        className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      />
                    </div>
                  </div>

                  <div className="space-y-1">
                    <label htmlFor="location" className="block text-sm font-medium text-gray-700">
                      Masjid City and State
                    </label>
                    <div className="mt-1">
                      <input
                        id="location"
                        name="location"
                        type="text"
                        required
                        className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      />
                    </div>
                  </div>

                  <div className="space-y-1">
                    <label htmlFor="bestcontact" className="block text-sm font-medium text-gray-700">
                      Best Way to Be Reached (Select)
                    </label>
                    <div className="mt-1">
                      <select 
                        id="bestcontact"
                        name="bestcontact"
                        required
                        className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      >
                        <option>Email</option>
                        <option>Phone</option>
                        <option>Text</option>
                        <option>Any</option>
                      </select>
                    </div>
                  </div>         
                  <div>
                    <button
                      type="submit"
                      disabled={state.submitting}
                      className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    >
                      > Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div className="hidden lg:block relative w-0 flex-1">
          <img
            className="absolute inset-0 h-full w-full object-cover"
            src="https://images.unsplash.com/photo-1519817650390-64a93db51149?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1527&q=80"
            alt=""
          />
        </div>
      </div>
    </>
  )
}
