import React, {useState, useEffect} from 'react'
import {useSearchParams} from "react-router-dom";
import { useForm, ValidationError } from '@formspree/react';
import MyDialog from "../components/successPopup.jsx"
import Logo from '../assets/Logo.png';
import axios from 'axios';


function Registration(params) {

  const [state, handleSubmit] = useForm("xeqdjvkq");
  const [showModal, setShowModal] = useState(false);
  const [hasAccount, setHasData] = useState(false);
  const [hasStripeAccount, setHasStripeAccount] = useState(false);
  const [hasDiscount, setHasDiscount] = useState(false);

  let [searchParams, setSearchParams] = useSearchParams();
  let blankData = {
    termslink:"",
    S1stripeaccount: "",
    S2stripeemail: "",
    S3stripediscount: "",
    I1orgname: "",
    I2ein: "",
    I3orgaddress: "",
    I4orgcity:  "",
    I5orgstate: "",
    I6orgzip: "",
    I7orgphone: "",
    I8orgurl: "", 
    II2name: "",
    II3email: "",
    II4title: "",
    II5bday: "",
    II6homeaddress: "",
    II7homecity: "",
    II8homestate: "",
    II9homezip: "",
    II10ssn: "",
    III1501c3url: "",
    IV1routing: "",
    IV2accountnumber: "",
    V1numAttendees: "",
    V2weeklyrevenue: "",
    V3annualrevenue: "",
    V4targetweeklyrevenue: "",
    V5targetannualrevenue: "",
    V6goals: "",
    VI1signature: "",
    VIIusername: "",
    VIIpassword: ""
  }
  let [regData, setRegData] = useState(blankData);
  let [preFillTrue, setPreFillTrue] = useState(false)
  let [hasCustomTerms, setHasCustomTerms] = useState(false);

  // Show top of page on load
  useEffect(() => {

    window.scrollTo(0, 0);

  }, []); 

  // Check if there is a pre-filled form available
  useEffect(() => {

    // Set the Registration URL
    var regURL = searchParams.get("reg");

    // Build an Org API Endpoint
    var regQuery = "https://donate.givemasjid.com/registration/?reg=" + regURL;

    // Retrieve data associated with the org
    const fetchData = async() => {
      try {
        const result = await axios(regQuery);   

        // Confirm that data was retrieved
        if(typeof result.data.account != "undefined" && result.data.registrationstatus != "complete"){
          setPreFillTrue(true);
          setRegData(result.data);

          // Check if the account has stripe enabled
          if (result.data.S1stripeaccount == "Yes"){
            setHasStripeAccount(true);
          }
          // Check if account has custom terms
          if(result.data.termslink != "") {
            setHasCustomTerms(true);
          }
        }
      }
      catch(err){

      }

    };

    // Run the fetch 
    fetchData();

  }, [searchParams])

  // Check if there's been a change to form state
  useEffect(() => {

    if (state.submitting) {
        setShowModal(true);
    }

  }, [state])

  // Form Dialog
  return (
    <>
      <MyDialog showState = {showModal} setShowState = {setShowModal} />
      <div className="min-h-full flex">
        <div className="flex-1 flex flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
          <div className="mx-auto w-full max-w-sm lg:w-96">
            <div>
            <a href="/">
              <img
                className="h-7 w-auto"
                src={Logo}
                alt="GiveMasjid"
              />
            </a>
              <h2 className="mt-6 text-2xl font-extrabold text-gray-900">New Account <span className="text-indigo-600">Registration</span> </h2>
              <p className="mt-2 text-sm text-gray-600">
                Asalamu Alaikum! Welcome to GiveMasjid. We're excited to begin working with you. As a first step, please provide the information below to help us create your account.
              </p>
              <p className={`mt-2 text-sm text-gray-600 ${preFillTrue ? '' : 'hidden'}`}>
                To get things moving, we've aleady collected some of this information for you. Be sure to confirm it's accuracy to help speed up the process. 
              </p>
            </div>

            <div className="mt-8">
              <div>
                <div className="mt-6 relative">
                  <div className="absolute inset-0 flex items-center" aria-hidden="true">
                    <div className="w-full border-t border-gray-300" />
                  </div>
                  <div className="relative flex justify-center text-sm">
                  </div>
                </div>
              </div>

              <div className="mt-6">

                <form onSubmit={handleSubmit} className="space-y-6">

                  {/*Part 0: Stripe Account*/}
                  <div>

                    <h3 className="mt-6 text-xl font-bold text-gray-900 mb-3">Stripe Account</h3>
                                    
                    <div>
                      <label htmlFor="S-1-StripeAccount" className="block text-sm font-medium text-gray-700">
                        Does your masjid have a <a href="https://stripe.com/">Stripe</a> account?*
                      </label>
                      <select
                        id="S-1-StripeAccount"
                        name="S-1-StripeAccount"
                        className="mt-1 block w-full pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-base rounded-md border py-2"
                        value= {regData.S1stripeaccount}
                        required
                        onChange={(e) => {
                          setHasStripeAccount(e.target.value === "Yes");
                          let regClone = regData;
                          regClone.S1stripeaccount = e.target.value;
                          setRegData(regClone)}
                        }
                      >
                        <option value="No">No / Unsure</option>
                        <option value="Yes">Yes</option>
                      </select>

                    </div>

                    <div className={`mt-3 ${hasStripeAccount ? "" : "hidden"}`}>
                      <label htmlFor="S-2-StripeEmail" className="block text-sm font-medium text-gray-700">
                        What email is associated with your Stripe account?*
                      </label>
                      <div className="mt-1">
                        <input
                          id="S-2-StripeEmail"
                          name="S-2-StripeEmail"
                          type="email"
                          autoComplete="email"
                          required = {hasStripeAccount}
                          className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                          defaultValue = {regData.S2stripeemail}
                          onChange={(e) => {
                            let regClone = regData;
                            regClone.S2stripeemail = e.target.value;
                            setRegData(regClone)}
                          }
                        />
                      </div>
                    </div>

                    <div className={`mt-3 ${hasStripeAccount ? "" : "hidden"}`}>
                      <label htmlFor="S-2-StripeDiscount" className="block text-sm font-medium text-gray-700">
                        Has your masjid received Stripe's non-profit rate?*
                      </label>
                      <select
                        id="S-2-StripeDiscount"
                        name="S-2-StripeDiscount"
                        className="mt-1 block w-full pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-base rounded-md border py-2"
                        value= {hasDiscount}
                        required = {hasStripeAccount}
                        onChange={(e) => {
                          let regClone = regData;
                          regClone.S3stripediscount = e.target.value
                          setRegData(regClone)
                          setHasDiscount(e.target.value);}
                        }
                      >
                        <option value="Yes">Yes</option>
                        <option value="No">No / Unsure</option>
                      </select>
                    </div>

                    <div className="mt-6 relative">
                      <div className="absolute inset-0 flex items-center" aria-hidden="true">
                        <div className="w-full border-t border-gray-300" />
                      </div>
                      <div className="relative flex justify-center text-sm">
                      </div>
                    </div>
                  </div>

                  <div>

                    {/*Part I: Masjid Information*/}
                    <div>

                      <h3 className="mt-6 text-xl font-bold text-gray-900 mb-3">Masjid Information</h3>
                      <div>
                        <label htmlFor="I-1-orgname" className="block text-sm font-medium text-gray-700">
                          Legal Organization Name*
                        </label>
                        <div className="mt-1">
                          <input
                            id="I-1-orgname"
                            name="I-1-orgname"
                            type="text"
                            autoComplete="organization"
                            required = {!hasStripeAccount}
                            className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            defaultValue= {regData.I1orgname}
                            onChange={(e) => {
                              let regClone = regData;
                              regClone.I1orgname = e.target.value
                              setRegData(regClone)}
                            }
                            > 
                          </input>
                        </div>
                      </div>

                      <div className={`mt-3 ${hasStripeAccount ? "hidden" : ""}`}>
                        <label htmlFor="I-2-ein" className="block text-sm font-medium text-gray-700">
                          EIN Number
                        </label>
                        <div className="mt-1">
                          <input
                            id="I-2-ein"
                            name="I-2-ein"
                            type="text"
                            // required = {!hasStripeAccount}
                            className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            defaultValue= {regData.I2ein}
                            onChange={(e) => {
                              let regClone = regData;
                              regClone.I2ein = e.target.value
                              setRegData(regClone)}
                            }
                          />
                        </div>
                      </div>

                      <div className="mt-3">
                        <label htmlFor="I-3-orgaddress" className="block text-sm font-medium text-gray-700">
                          Street Address*
                        </label>
                        <div className="mt-1">
                          <input
                            id="I-3-orgaddress"
                            name="I-3-orgaddress"
                            type="text"
                            autoComplete="address"
                            required = {!hasStripeAccount}
                            className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            defaultValue= {regData.I3orgaddress}
                            onChange={(e) => {
                              let regClone = regData;
                              regClone.I3orgaddress = e.target.value
                              setRegData(regClone)}
                            }
                          />
                        </div>
                      </div>

                      <div className="mt-3">
                        <label htmlFor="I-4-orgcity" className="block text-sm font-medium text-gray-700">
                          City*
                        </label>
                        <div className="mt-1">
                          <input
                            id="I-4-orgcity"
                            name="I-4-orgcity"
                            type="text"
                            autoComplete="home city"
                            required = {!hasStripeAccount}
                            className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            defaultValue= {regData.I4orgcity}
                            onChange={(e) => {
                              let regClone = regData;
                              regClone.I4orgcity = e.target.value
                              setRegData(regClone)}
                            }
                          />
                        </div>
                      </div>

                      <div className="mt-3">
                        <label htmlFor="I-5-orgstate" className="block text-sm font-medium text-gray-700">
                          State*
                        </label>
                        <div className="mt-1">
                          <input
                            id="I-5-orgstate"
                            name="I-5-orgstate"
                            type="text"
                            autoComplete="home state"
                            required = {!hasStripeAccount}
                            className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            defaultValue= {regData.I5orgstate}
                            onChange={(e) => {
                              let regClone = regData;
                              regClone.I5orgstate = e.target.value
                              setRegData(regClone)}
                            }
                          />
                        </div>
                      </div>

                      <div className="mt-3">
                        <label htmlFor="I-6-orgzip" className="block text-sm font-medium text-gray-700">
                          Zip Code*
                        </label>
                        <div className="mt-1">
                          <input
                            id="I-6-orgzip"
                            name="I-6-orgzip"
                            type="text"
                            autoComplete="postal-code"
                            required = {!hasStripeAccount}
                            className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            defaultValue= {regData.I6orgzip}
                            onChange={(e) => {
                              let regClone = regData;
                              regClone.I6orgzip = e.target.value
                              setRegData(regClone)}
                            }
                          />
                        </div>
                      </div>

                      <div className={`mt-3 ${hasStripeAccount ? "hidden" : ""}`}>
                        <label htmlFor="I-7-orgphone" className="block text-sm font-medium text-gray-700">
                          Organization Phone Number
                        </label>
                        <div className="mt-1">
                          <input
                            id="I-7-orgphone"
                            name="I-7-orgphone"
                            type="text"
                            autoComplete="tel"
                            // required = {!hasStripeAccount}
                            className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            defaultValue= {regData.I7orgphone}
                            onChange={(e) => {
                              let regClone = regData;
                              regClone.I7orgphone = e.target.value
                              setRegData(regClone)}
                            }
                          />
                        </div>
                      </div>

                      <div className="mt-3">
                        <label htmlFor="I-8-orgurl" className="block text-sm font-medium text-gray-700">
                          Website
                        </label>
                        <div className="mt-1">
                          <textarea
                            id="I-8-orgurl"
                            name="I-8-orgurl"
                            type="text"
                            autoComplete="url"
                            className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            defaultValue= {regData.I8orgurl}
                            onChange={(e) => {
                              let regClone = regData;
                              regClone.I8orgurl = e.target.value
                              setRegData(regClone)}
                            }                          
                          />
                        </div>
                      </div>

                      <div className="mt-6 relative">
                        <div className="absolute inset-0 flex items-center" aria-hidden="true">
                          <div className="w-full border-t border-gray-300" />
                        </div>
                        <div className="relative flex justify-center text-sm">
                        </div>
                      </div>
                    </div>

                    {/*Part II: Masjid Representative*/}
                    <div>

                      <h3 className="mt-6 text-xl font-bold text-gray-900 mb-3">Masjid Representative</h3>
                      <div>
                        <label htmlFor="II-2-name" className="block text-sm font-medium text-gray-700">
                          Full Legal Name*
                        </label>
                        <div className="mt-1">
                          <input
                            id="II-2-name"
                            name="II-2-name"
                            type="text"
                            autoComplete="name"
                            required = {!hasStripeAccount}
                            className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            defaultValue= {regData.II2name}
                            onChange={(e) => {
                              let regClone = regData;
                              regClone.II2name = e.target.value
                              setRegData(regClone)}
                            }          
                          />
                        </div>
                      </div>

                      <div className="mt-3">
                        <label htmlFor="II-3-email" className="block text-sm font-medium text-gray-700">
                          Email Address*
                        </label>
                        <div className="mt-1">
                          <input
                            id="II-3-email"
                            name="II-3-email"
                            type="email"
                            autoComplete="email"
                            required = {!hasStripeAccount}
                            className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            defaultValue= {regData.II3email}
                            onChange={(e) => {
                              let regClone = regData;
                              regClone.II3email = e.target.value
                              setRegData(regClone)}
                            }                                  
                          />

                          <ValidationError 
                            prefix="Email" 
                            field="II-3-email"
                            errors={state.errors}
                          />

                        </div>
                      </div>

                      <div className="mt-3">
                        <label htmlFor="II-4-title" className="block text-sm font-medium text-gray-700">
                          Job Title*
                        </label>
                        <div className="mt-1">
                          <input
                            id="II-4-title"
                            name="II-4-title"
                            type="text"
                            autoComplete="organization-title"
                            required = {!hasStripeAccount}
                            className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            defaultValue= {regData.II4title}
                            onChange={(e) => {
                              let regClone = regData;
                              regClone.II4title = e.target.value
                              setRegData(regClone)}
                            }                                   
                          />
                        </div>
                      </div>

                      <div className={`mt-3 ${hasStripeAccount ? "hidden" : ""}`}>
                        <label htmlFor="II-5-bday" className="block text-sm font-medium text-gray-700">
                          Date of Birth*
                        </label>
                        <div className="mt-1">
                          <input
                            id="II-5-bday"
                            name="II-5-bday"
                            type="text"
                            autoComplete="bday"
                            required = {!hasStripeAccount}
                            className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            defaultValue= {regData.II5bday}
                            onChange={(e) => {
                              let regClone = regData;
                              regClone.II5bday = e.target.value
                              setRegData(regClone)}
                            }                                
                          />
                        </div>
                      </div>

                      <div className={`mt-3 ${hasStripeAccount ? "hidden" : ""}`}>
                        <label htmlFor="II-6-homeaddress" className="block text-sm font-medium text-gray-700">
                          Home Street Address* 
                        </label>
                        <div className="mt-1">
                          <input
                            id="II-6-homeaddress"
                            name="II-6-homeaddress"
                            type="text"
                            autoComplete="address"
                            required = {!hasStripeAccount}
                            className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            defaultValue= {regData.II6homeaddress}
                            onChange={(e) => {
                              let regClone = regData;
                              regClone.II6homeaddress = e.target.value
                              setRegData(regClone)}
                            }                                              
                          />
                        </div>
                      </div>

                      <div className={`mt-3 ${hasStripeAccount ? "hidden" : ""}`}>
                        <label htmlFor="II-7-homecity" className="block text-sm font-medium text-gray-700">
                          City*
                        </label>
                        <div className="mt-1">
                          <input
                            id="II-7-homecity"
                            name="II-7-homecity"
                            type="text"
                            autoComplete="home city"
                            required = {!hasStripeAccount}
                            className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            defaultValue= {regData.II7homecity}
                            onChange={(e) => {
                              let regClone = regData;
                              regClone.II7homecity = e.target.value
                              setRegData(regClone)}
                            }                                    
                          />
                        </div>
                      </div>

                      <div className={`mt-3 ${hasStripeAccount ? "hidden" : ""}`}>
                        <label htmlFor="II-8-homestate" className="block text-sm font-medium text-gray-700">
                          State*
                        </label>
                        <div className="mt-1">
                          <input
                            id="II-8-homestate"
                            name="II-8-homestate"
                            type="text"
                            autoComplete="home state"
                            required = {!hasStripeAccount}
                            className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            defaultValue= {regData.II8homestate}
                            onChange={(e) => {
                              let regClone = regData;
                              regClone.II8homestate = e.target.value
                              setRegData(regClone)}
                            }           
                          />
                        </div>
                      </div>

                      <div className={`mt-3 ${hasStripeAccount ? "hidden" : ""}`}>
                        <label htmlFor="II-9-homezip" className="block text-sm font-medium text-gray-700">
                          Zipcode*
                        </label>
                        <div className="mt-1">
                          <input
                            id="II-9-homezip"
                            name="II-9-homezip"
                            type="text"
                            autoComplete="postal-code"
                            required = {!hasStripeAccount}
                            className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            defaultValue= {regData.II8homestate}
                            onChange={(e) => {
                              let regClone = regData;
                              regClone.II8homestate = e.target.value
                              setRegData(regClone)}
                            }                                     
                          />
                        </div>
                      </div>

                      <div className={`mt-3 ${hasStripeAccount ? "hidden" : ""}`}>
                        <label htmlFor="II-10-ssn" className="block text-sm font-medium text-gray-700">
                          Last Four Digits of SSN*
                        </label>
                        <div className="mt-1">
                          <input
                            id="II-10-ssn"
                            name="II-10-ssn"
                            type="number"
                            required = {!hasStripeAccount}
                            className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            defaultValue= {regData.II10ssn}
                            onChange={(e) => {
                              let regClone = regData;
                              regClone.II10ssn = e.target.value
                              setRegData(regClone)}
                            }                                     
                          />
                        </div>
                      </div>

                      <div className="mt-6 relative">
                        <div className="absolute inset-0 flex items-center" aria-hidden="true">
                          <div className="w-full border-t border-gray-300" />
                        </div>
                        <div className="relative flex justify-center text-sm">
                        </div>
                      </div>
                    </div>   

                    {/*Part III: 501C3 Determination Letter*/}
                    <div>

                      <h3 className="mt-6 text-xl font-bold text-gray-900 mb-3">501c3 Confirmation</h3>
                      <div>
                        <label htmlFor="III-1-501c3url" className="block text-sm font-medium text-gray-700">
                          Link to 501c3 Determination Letter
                        </label>
                        <div className="mt-1">
                          <textarea
                            id="III-1-501c3url"
                            name="III-1-501c3url"
                            type="text"
                            // required = {!hasStripeAccount}
                            className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            defaultValue= {regData.III1501c3url}
                            onChange={(e) => {
                              let regClone = regData;
                              regClone.III1501c3url = e.target.value
                              setRegData(regClone)}
                            }                             
                          />
                        </div>
                      </div>

                      <div className="mt-6 relative">
                        <div className="absolute inset-0 flex items-center" aria-hidden="true">
                          <div className="w-full border-t border-gray-300" /> 
                        </div>
                        <div className="relative flex justify-center text-sm"></div>
                      </div>
                    </div> 

                    {/*Part IV: Bank Information*/}
                    <div className={`mt-3 ${hasStripeAccount ? "hidden" : ""}`}>

                      <h3 className="mt-6 text-xl font-bold text-gray-900 mb-3">Bank Information</h3>
                      <div>
                        <label htmlFor="IV-1-routing" className="block text-sm font-medium text-gray-700">
                          Routing Number*
                        </label>
                        <div className="mt-1">
                          <input
                            id="IV-1-routing"
                            name="IV-1-routing"
                            type="text"
                            autoComplete="text"
                            required = {!hasStripeAccount}
                            className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            defaultValue= {regData.IV1routing}
                            onChange={(e) => {
                              let regClone = regData;
                              regClone.IV1routing = e.target.value
                              setRegData(regClone)}
                            }                             
                          />
                        </div>
                      </div>

                      <div className="mt-3">
                        <label htmlFor="IV-2-accountnumber" className="block text-sm font-medium text-gray-700">
                          Account Number*
                        </label>
                        <div className="mt-1">
                          <input
                            id="IV-2-accountnumber"
                            name="IV-2-accountnumber"
                            type="text"
                            required = {!hasStripeAccount}
                            className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            defaultValue= {regData.IV2accountnumber}
                            onChange={(e) => {
                              let regClone = regData;
                              regClone.IV2accountnumber = e.target.value
                              setRegData(regClone)}
                            }                             
                          />
                        </div>
                      </div>

                      <div className="mt-6 relative">
                        <div className="absolute inset-0 flex items-center" aria-hidden="true">
                          <div className="w-full border-t border-gray-300" /> 
                        </div>
                        <div className="relative flex justify-center text-sm"></div>
                      </div>
                    </div>

                  </div>

                  {/*Part V: Masjid Goals*/}
                  <div>

                    <h3 className="mt-6 text-xl font-bold text-gray-900 mb-3">Masjid Financial Goals</h3>
                    <div>
                      <label htmlFor="V-1-numattendees" className="block text-sm font-medium text-gray-700">
                        Estimated Number of Weekly Jummah Attendees
                      </label>
                      <div className="mt-1">
                        <input
                          id="V-1-numAttendees"
                          name="V-1-numAttendees"
                          type="number"
                          className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                          defaultValue= {regData.V1numAttendees}
                            onChange={(e) => {
                              let regClone = regData;
                              regClone.V1numAttendees = e.target.value
                              setRegData(regClone)}
                            }                           
                        />
                      </div>
                    </div>

                    {/* <div className="mt-3">
                      <label htmlFor="V-2-weeklyrevenue" className="block text-sm font-medium text-gray-700">
                        Estimated Weekly Jummah Revenue
                      </label>
                      <div className="mt-1">
                        <input
                          id="V-2-weeklyrevenue"
                          name="V-2-weeklyrevenue"
                          type="number"
                          className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                          defaultValue= {regData.V2weeklyrevenue}
                            onChange={(e) => {
                              let regClone = regData;
                              regClone.V2weeklyrevenue = e.target.value
                              setRegData(regClone)}
                            }                           
                        />
                      </div>
                    </div>


                    <div className="mt-3">
                      <label htmlFor="V-3-annualrevenue" className="block text-sm font-medium text-gray-700">
                        Estimated Annual Revenue
                      </label>
                      <div className="mt-1">
                        <input
                          id="V-3-annualrevenue"
                          name="V-3-annualrevenue"
                          type="text"
                          className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                          defaultValue= {regData.V3annualrevenue}
                            onChange={(e) => {
                              let regClone = regData;
                              regClone.V3annualrevenue = e.target.value
                              setRegData(regClone)}
                            }                           
                        />
                      </div>
                    </div>

                    <div className="mt-3">
                      <label htmlFor="V-4-targetweeklyrevenue" className="block text-sm font-medium text-gray-700">
                        Target Weekly Jummah Donation Goal
                      </label>
                      <div className="mt-1">
                        <input
                          id="V-4-targetweeklyrevenue"
                          name="V-4-targetweeklyrevenue"
                          type="text"
                          className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                          defaultValue= {regData.V4targetweeklyrevenue}
                            onChange={(e) => {
                              let regClone = regData;
                              regClone.V4targetweeklyrevenue = e.target.value
                              setRegData(regClone)}
                            }                                
                        />
                      </div>
                    </div>*/}

                    <div className="mt-3">
                      <label htmlFor="V-5-targetannualrevenue" className="block text-sm font-medium text-gray-700">
                        Target Annual Donation Goals
                      </label>
                      <div className="mt-1">
                        <input
                          id="V-5-targetannualrevenue"
                          name="V-5-targetannualrevenue"
                          type="text"
                          className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                          defaultValue= {regData.V5targetannualrevenue}
                            onChange={(e) => {
                              let regClone = regData;
                              regClone.V5targetannualrevenue = e.target.value
                              setRegData(regClone)}
                            }                                
                          />
                      </div>
                    </div> 

                    <div className="mt-3">
                      <label htmlFor="V-6-goals" className="block text-sm font-medium text-gray-700">
                        Are there any specific projects you are currently looking to actively raise funds for?
                      </label>
                      <div className="mt-1">
                        <textarea
                          id="V-6-goals"
                          name="V-6-goals"
                          className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                          defaultValue= {regData.V6goals}
                            onChange={(e) => {
                              let regClone = regData;
                              regClone.V6goals = e.target.value
                              setRegData(regClone)}
                            }                                
                        />
                      </div>
                    </div>

                    <div className="mt-6 relative">
                      <div className="absolute inset-0 flex items-center" aria-hidden="true">
                        <div className="w-full border-t border-gray-300" />
                      </div>
                      <div className="relative flex justify-center text-sm">
                      </div>
                    </div>
                  </div> 

                  {/*Part VII: Username Creation*/}
                  <div>

                    <h3 className="mt-6 text-xl font-bold text-gray-900 mb-3">GiveMasjid Credentials</h3>
                    <div>
                      <label htmlFor="V-1-numattendees" className="block text-sm font-medium text-gray-700">
                        Set Your GiveMasjid Username (or Email Address)*
                      </label>
                      <div className="mt-1">
                        <input
                          id="VII-1-username"
                          name="VII-1-username"
                          type="text"
                          required
                          className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                          defaultValue= {regData.VIIusername}
                            onChange={(e) => {
                              let regClone = regData;
                              regClone.VIIusername = e.target.value
                              setRegData(regClone)}
                            }                           
                        />
                      </div>
                    </div>

                    <div className="mt-3">
                      <label htmlFor="V-1-numattendees" className="block text-sm font-medium text-gray-700">
                        Set Your GiveMasjid Password*
                      </label>
                      <div className="mt-1">
                        <input
                          id="VII-2-password"
                          name="VII-2-password"
                          type="password"
                          required
                          className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                          defaultValue= {regData.VIIpassword}
                            onChange={(e) => {
                              let regClone = regData;
                              regClone.VIIpassword = e.target.value
                              setRegData(regClone)}
                            }                           
                        />
                      </div>
                    </div>

                    <div className="mt-6 relative">
                      <div className="absolute inset-0 flex items-center" aria-hidden="true">
                        <div className="w-full border-t border-gray-300" />
                      </div>
                      <div className="relative flex justify-center text-sm">
                      </div>
                    </div>
                  </div> 
                  {/*Part VI: Terms and Services*/}
                  <div>

                    <h3 className="mt-6 text-xl font-bold text-gray-900 mb-3">Submit</h3>
                    <div>
                      <label htmlFor="VI-1-signature" className="block text-sm font-medium text-gray-700">
                        Please type your full name to confirm receipt and agreement to our <span className="text-blue-600"><a href={`${hasCustomTerms ? regData.termslink : 'https://www.givemasjid.com/terms/GiveMasjid_Terms_and_Conditions.pdf'}`}> Terms of Service* </a></span>.
                      </label>
                      <div className="mt-1">
                        <input
                          id="VI-1-signature"
                          name="VI-1-signature"
                          type="text"
                          autoComplete="name"
                          required
                          className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        />
                      </div>
                      <label htmlFor="VI-2-terms" className="block text-sm font-medium text-gray-700 hidden">
                        Terms of Agreement Sent to User
                      </label>
                      <div className="mt-1">
                        <textarea
                          id="VI-2-terms"
                          name="VI-2-terms"
                          type="text"
                          required
                          value={`${hasCustomTerms ? regData.termslink : 'https://www.givemasjid.com/terms/GiveMasjid_Terms_and_Conditions.pdf'}`}
                            onChange={(e) => {
                              let regClone = regData;
                              regClone.termslink  = e.target.value
                              setRegData(regClone)}
                            }                                  
                          className="hidden appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        />
                      </div>
                    </div>


                    <div className="mt-6 relative">
                      <div className="absolute inset-0 flex items-center" aria-hidden="true">
                        <div className="w-full border-t border-gray-300" />
                      </div>
                      <div className="relative flex justify-center text-sm">
                      </div>
                    </div>
                  </div> 

                  <div>
                    <button
                      type="submit"
                      disabled={state.submitting}
                      className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    >
                      > Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div className="hidden lg:block relative w-0 flex-1">
          <img
            className="absolute inset-0 h-full w-full object-cover"
            src="https://images.unsplash.com/photo-1527838700789-f9b453e73655?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2540&q=80"
            alt=""
          />
        </div>
      </div>
    </>
  )
}

// Export Component
export default Registration;